import { Options } from 'ky'
import { KyHeadersInit } from 'ky/distribution/types/options'
import { ENV } from 'src/utils/env'
import { getWindowNextDataProps } from 'src/utils/get-window-next-data'

// NOTE: This cannot be tested until `setupTests.tsx` is broken up. This is imported directly through the auth hook which is very widely used.
export function createKeycloakAugmentWebConfiguration(): Options {
  return {
    prefixUrl: ENV.KEYCLOAK_AUGMENT_WEB_API_URL,
    headers: getHeaders(),
  }
}

function getHeaders(): KyHeadersInit {
  const { token } = getWindowNextDataProps()

  return {
    Authorization: token ? `Bearer ${token}` : undefined,
  }
}
