import { Options } from 'ky'
import { KyHeadersInit } from 'ky/distribution/types/options'
import { ENV } from 'src/utils/env'

export function createNotifyHttpClientConfiguration(): Options {
  return {
    prefixUrl: getPrefixURL(),
    retry: 0,
    headers: getHeaders(),
  }
}

function getPrefixURL(): string {
  return ENV.NOTIFY_API_URL
}

function getHeaders(): KyHeadersInit {
  return {
    'Notify-Authorization': `Notify-Client ${getClientToken()}`,
  }
}

function getClientToken(): string {
  return ENV.NOTIFY_CLIENT_TOKEN
}
