import ky, { Hooks, HTTPError, KyResponse, Options } from 'ky'
import { KyInstance } from 'ky/distribution/types/ky'
import { HttpClientRequestError } from 'src/api/http-client-request-error'
import handleRefreshTokenIfExpired from 'src/api/utils/handleRefreshTokenIfExpired'
import logger from 'src/utils/logger'

// NOTE: This should be integration tested.
// NOTE: Add HTTP methods as needed.
export default class HttpClient {
  private kyInstance: KyInstance

  constructor(configuration: Options) {
    this.kyInstance = ky.create({
      hooks: this.createHooks(),
      ...configuration,
    })
  }

  private createHooks(): Hooks {
    return {
      beforeRequest: [handleRefreshTokenIfExpired],
      beforeError: [this.handleError()],
    }
  }

  private handleError() {
    return async (kyError: HTTPError) => {
      const formattedError = new HttpClientRequestError(kyError)

      logger.error({
        message: 'HTTP Client Request Failure',
        context: {
          request: await formattedError.getRequestDetails(),
          response: await formattedError.getResponseDetails(),
        },
      })

      return kyError
    }
  }

  // Requests
  async get<T>(path: string): Promise<T> {
    const response = await this.kyInstance.get(path)
    return this.parse<T>(response)
  }

  async put<T>(path: string, body?: object): Promise<T> {
    const response = await this.kyInstance.put(path, { json: body })
    return this.parse<T>(response)
  }

  async delete<T>(path: string, body?: object): Promise<T> {
    const response = await this.kyInstance.delete(path, { json: body })
    return this.parse<T>(response)
  }

  // Parsing
  // NOTE: Ky seems to reject if the response is not OK, so doing that check here would be redundant.
  private async parse<T>(response: KyResponse): Promise<T> {
    try {
      return await response.json<T>()
    } catch (reason) {
      return this.handleParsingError(reason)
    }
  }

  private handleParsingError<T>(reason: any): Promise<T> {
    this.logParsingError(reason)
    return Promise.reject(reason)
  }

  private logParsingError(reason: any) {
    logger.error({
      message: 'HTTP Client Parsing Failure',
      context: { reason },
    })
  }
}
