import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
)

const baseStyle = definePartsStyle({
  control: {
    _disabled: {
      bg: 'none',
      opacity: 0.4,
      borderColor: 'gray.700',
    },
  },
})

export const CheckboxStyleConfig = defineMultiStyleConfig({ baseStyle })
