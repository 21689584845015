import { bidlClient } from 'src/api/bidl/client'

const aggregatorCrmBase = 'api/aggregator/crm'

export const crmRequests = {
  createCustomer: (customer: CreateCustomerRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CreateCustomer`, {
        json: customer,
      })
      .json<AggregatorBasicIdResponse>(),

  updateCustomer: (customer: Customer) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateCustomer`, {
        json: customer,
      })
      .json<AggregatorBasicIdResponse>(),

  searchCustomers: (value: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/SearchCustomer`, {
        json: {
          value,
        },
      })
      .json<SearchedCustomerResponse>(),

  getContactInformationByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetContactInformationByCustomerId`, {
        json: { id },
      })
      .json<GetContactInformationByCustomerIdResponse>(),

  getDemographicByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetDemographicByCustomerId`, {
        json: {
          id,
        },
      })
      .json<GetDemographicByCustomerIdResponse>(),

  updateDemographic: (demographic: UpdateDemographicRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateDemographic`, {
        json: demographic,
      })
      .json<UpdateDemographicResponse>(),

  createNote: (note: CreateNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/CreateNote`, {
        json: note,
      })
      .json<Note>(),

  deleteNote: (noteId: DeleteNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/DeleteNote`, {
        json: noteId,
      })
      .json<DeleteNoteResponse>(),

  getNote: (noteId: GetNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetNote`, {
        json: noteId,
      })
      .json<Note>(),

  listNotesByCustomerId: (options: GetNotesByCustomerIdRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetNotesByCustomerId`, {
        json: options,
      })
      .json<GetNotesByCustomerIdResponse>(),

  updateNote: (note: UpdateNoteRequest) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/UpdateNote`, {
        json: note,
      })
      .json<Note>(),

  getInvoiceLocationsByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetInvoiceLocationsByCustomerId`, {
        json: { id },
      })
      .json<GetInvoiceLocationsByCustomerIdResponse>(),

  getDeliveryDestinationsByCustomerId: (id: string) =>
    bidlClient
      .post(`${aggregatorCrmBase}/v1/GetDeliveryDestinationsByCustomerId`, {
        json: { id },
      })
      .json<GetDeliveryDestinationsByCustomerIdResponse>(),
}
