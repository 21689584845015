import {
  InfiniteData,
  Query,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { HTTPError } from 'src/api'
import { standaloneToast } from 'src/components/designsystem/toast'

export const DEFAULT_PAGE_SIZE = 20

type PagedResponse<T> = { data: T[]; meta: { pagination: Pagination } }

type PagedQuery<T> = UseInfiniteQueryResult<InfiniteData<PagedResponse<T>>, unknown>

/**
 * Common helper for react-query InfiniteQueries.
 * This determines the next page value for any paged Centre APIs.
 *
 * @param lastGroup -
 * @param allGroups -
 * @returns number
 */
export function getNextPageParam<T>(
  lastGroup: PagedResponse<T>,
  _allGroups: PagedResponse<T>[]
): number {
  if (!lastGroup) return undefined

  const currentPage = lastGroup.meta?.pagination?.current_page
  const totalPages = lastGroup.meta?.pagination?.total_pages

  if (!currentPage || !totalPages) return undefined

  return currentPage < totalPages ? currentPage + 1 : undefined
}

export function getAggregatorNextPageParamFactory<T>(
  pageSize = DEFAULT_PAGE_SIZE
): (lastGroup: PaginatedAggregatorResponse<T>) => string | undefined {
  return (lastGroup: PaginatedAggregatorResponse<T>) => {
    if (!lastGroup || lastGroup?.data.length <= pageSize) return undefined

    return lastGroup.continuationToken
  }
}

/**
 * Returns the total item count from a paged Centre API response.
 * All responses should have the same total item value.
 *
 * @param itemQuery - a PagedQuery to pull the total count from
 * @returns number - A count, where -1 indicates the total cannot be determined
 */
export function getPagedQueryTotalCount<T>(itemQuery: PagedQuery<T>) {
  const pagination = itemQuery.data?.pages?.[0]?.meta?.pagination

  if (!pagination) return 0

  return pagination?.total || 0
}

/**
 * This handler is used in the creation of the `QueryClient` for global query error-handling.
 * By default, if a query's `meta` property defines an `errorMessage` property it will trigger
 * an error toast using `errorMessage` and optionally, `errorTitle`.
 *
 * As a near-future todo - inspect `error.response` (`status` + `payload`) for a force-logout error
 */
export async function globalQueryErrorHandler(
  error: HTTPError,
  query: Query<unknown, unknown, unknown>
) {
  if (!query.meta?.errorMessage) return
  if (typeof query.meta?.isAlertable === 'function') {
    if (query.meta?.isAlertable(error) === false) return
  }

  const errorTitle = (query.meta?.errorTitle as string) ?? 'An Error Occurred'
  const errorMessage = query.meta?.errorMessage as string

  standaloneToast({
    status: 'error',
    title: errorTitle,
    description: errorMessage,
  })
}

/**
 * Generic wrapper around useQuery that allows us to type the data and proper
 * error type from Ky, reducing boilerplate and duplicative types. At most it
 * expects a single type for the data, and an optional error type.
 * @param options - Options to pass to useQuery, does not support polymorphic invocation
 * @returns Query object
 */
export const useQueryTyped = <TFnData, UError = HTTPError, TData = TFnData>(
  options: UseQueryOptions<TFnData, UError, TData>
) => useQuery<TFnData, UError, TData>(options)

/**
 * For use when extending useQueryTyped
 * @see useQueryTyped
 */
export type UseQueryOptionsTyped<TFnData, UError = HTTPError, TData = TFnData> = Partial<
  UseQueryOptions<TFnData, UError, TData>
>

/**
 * Generic wrapper around useInfiniteQuery that allows us to type the data and proper
 * error type from Ky, reducing boilerplate and duplicative types.
 * @param options - Options to pass to useInfiniteQuery, does not support polymorphic invocation
 * @returns Infinite Query object
 */
export function useInfiniteQueryTyped<TData, UError = HTTPError, TPageParam = number>(
  options: UseInfiniteQueryOptions<TData, UError, InfiniteData<TData>, TData, QueryKey, TPageParam>
) {
  return useInfiniteQuery<TData, UError, InfiniteData<TData>>(options)
}

/**
 * For use when extending useInfiniteQueryTyped
 * @see useInfiniteQueryTyped
 */
export type UseInfiniteQueryOptionsTyped<TData, UError = HTTPError, TPageParam = number> = Partial<
  UseInfiniteQueryOptions<
    TData,
    UError,
    InfiniteData<TData, TPageParam>,
    TData,
    QueryKey,
    TPageParam
  >
>

/**
 * Generic wrapper around useMutation that allows us to type the data and proper
 * error type from Ky, reducing boilerplate and duplicative types.
 * @param options - Options to pass to useMutation, does not support polymorphic invocation
 * @returns Mutation object
 */
export const useMutationTyped = <TData, VBody = void, UError = HTTPError, TContext = unknown>(
  options: UseMutationOptions<TData, UError, VBody, TContext>
) => useMutation<TData, UError, VBody, TContext>(options)

/**
 * For use when extending useMutationTyped
 * @see useMutationTyped
 */
export type UseMutationOptionsTyped<
  TData,
  VBody = void,
  TContext = unknown,
  UError = HTTPError,
> = UseMutationOptions<TData, UError, VBody, TContext>

/** Generic type for a paged response */
type PageData<T> = { data: T[] }
/**
 * Utility function to flatten paged data into a single array.
 * Ideal for use with useMemo to prevent unnecessary re-renders.
 * @param data - InfiniteData object from react-query
 * @returns T[] - Flattened array of data
 */
export const flattenPages = <T>(data?: InfiniteData<PageData<T>>): T[] =>
  data?.pages.flatMap((page) => page.data) ?? []
