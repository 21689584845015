import { Fragment, useEffect, useState } from 'react'
import * as api from 'src/api'
import { useAuth } from 'src/auth'
import { useMerchandiser } from 'src/data/merchandiser'
import { ErrorState, LoadingState } from 'src/components/resource'
import { FLAG_KEYS, useGlobalFlag } from 'src/utils/flagr'
import ENV from 'src/utils/env'
import { UnselectedPage } from './UnselectedPage'

export function MerchandiserPageCheck({ children }: Readonly<{ children: React.ReactNode }>) {
  const { isStaff, hasPermission } = useAuth()
  const isStaffFlagEnabled = useGlobalFlag(FLAG_KEYS.WEBAPP_STAFF_ROUTES)
  const { userId, selectedUserQuery, selectedAccountsQuery, selectedUser, isUnknownId } =
    useMerchandiser()
  const hasDataViewerPermission = hasPermission('data_viewer')
  const staffView =
    (isStaff || ENV.MOCK_MERCH_MODE) && (isStaffFlagEnabled || hasDataViewerPermission)

  if (staffView) {
    if (selectedUserQuery.isFetching || selectedAccountsQuery.isFetching) return <LoadingState />
    if (!hasDataViewerPermission) {
      return (
        <ErrorState
          isVerticallyCentered
          header="Request Access"
          subHeader="Please reach out to your administrator for access."
        />
      )
    }
    if (!userId || isUnknownId) return <UnselectedPage />
    if (selectedUserQuery.isError || selectedAccountsQuery.isError) return <ErrorState />
  }

  return (
    <PageCheckWithHeader selectedUser={selectedUser} userId={userId} isStaffView={staffView}>
      {children}
    </PageCheckWithHeader>
  )
}

interface PageCheckWithHeaderProps {
  children: React.ReactNode
  userId: string
  isStaffView: boolean
  selectedUser: SearchUser
}

function PageCheckWithHeader({
  userId,
  isStaffView,
  selectedUser,
  children,
}: Readonly<PageCheckWithHeaderProps>) {
  const [isHeaderSet, setIsHeaderSet] = useState(!userId)
  const actualUserId = selectedUser?.userId ? String(selectedUser?.userId) : null

  useEffect(() => {
    if (!isStaffView || !actualUserId) return

    api.clearCentreAppHeaderImpersonation()
    api.clearReportingServiceClientHeaderImpersonation()
    api.clearPaymentsServiceHeaderImpersonation()

    api.updateCentreAppHeader('X-Impersonate-Id', actualUserId)
    api.updateReportingServiceClientHeader('X-Impersonate-Id', actualUserId)
    api.updatePaymentsServiceHeader('X-Impersonate-Id', actualUserId)

    setIsHeaderSet(true)
  }, [isStaffView, actualUserId])

  if (!isHeaderSet) return <LoadingState />

  // When the selected user changes, the `key` here will cause the page to un-mount and re-mount
  return <Fragment key={userId || 'grower'}>{children}</Fragment>
}
